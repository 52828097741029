.support-screen {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .container {
    flex: 1;
    display: flex;
    margin: 0 auto;
    padding: 20px;
    max-width: 600px;
    flex-direction: column;
    justify-content: center;
  }
  
  .cards-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }
  
  .card {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .card p {
    font-size: 14px;
    color: #555;
  }
  
  .support-form {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .support-form label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .support-form input[type="text"],
  .support-form input[type="email"],
  .support-form textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .support-form textarea {
    height: 100px;
    resize: none;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #2355C4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .support-info {
    font-size: 14px;
    margin-top: 20px;
  }
  